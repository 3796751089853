<template>
  <div>
    <bread-crumb></bread-crumb>
    <section class="informational-security container container__mini2">
      <h2 class="informational-security__title">PALO ALTO</h2>

      <div class="informational-security__wrap reverse">
        <div class="informational-security__wrap--img">
          <div class="informational-security__wrap--img--inner">
            <v-lazy-image
              :src="url + data.image"
              width="628"
              height="314"
              alt="image-logo"
            />
          </div>
        </div>
        <h3 class="informational-security__wrap--descr">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
          consequat massa quis enim. Donec pede justo, fringilla vel, aliquet
          nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
          venenatis vitae, justo
        </h3>
      </div>

      <div class="informational-security__details details">
        <div class="details__left">
          <img
            src="~@/assets/images/display.svg"
            width="61"
            height="61"
            alt="display"
          />

          <div class="details__wrap">
            <h3 class="details__wrap--title">Міжмережевий Екран</h3>
            <a href="#">Докладніше</a>
          </div>
        </div>
        <div class="details__right">
          <img
            src="~@/assets/images/settings.svg"
            width="61"
            height="61"
            alt="display"
          />

          <div class="details__wrap">
            <h3 class="details__wrap--title">Програмне забезпечення</h3>
            <a href="#">Докладніше</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import axios from "axios";

export default {
  components: {
    BreadCrumb,
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  computed: {
    getUser: {
      get() {
        let result = null;
        this.users.forEach((element) => {
          if (element.slug === this.$route.params.name) {
            result = element;
          }
        });
        return result;
      },
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      data: [],
      users: [],
    };
  },
  methods: {
    async getAboutComPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/about-company-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getSliders() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/all-sliders",
          config
        );
        this.users = response.data.about_company_slider.data.users.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getAboutComPage();
    this.getSliders();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.informational-security {
  display: flex;
  flex-direction: column;
  @include property("margin-bottom", 150, 30);

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 48, 25);
  }

  &__wrap {
    display: grid;
    @include property("gap", 65, 20, true, 1670, 1024);
    @include property("margin-bottom", 45, 15);
    justify-content: center;

    @media (min-width: 768px) {
      &:not(.reverse) {
        grid-template-columns: 1.5fr 1fr;
      }

      &.reverse {
        grid-template-columns: 1fr 1.5fr;
      }
    }

    &--img {
      background: url("~@/assets/images/bg-card.jpg") no-repeat;
      background-size: cover;
      width: 100%;
      align-self: flex-start;

      .reverse & {
        order: 2;
      }

      @media (max-width: 767.98px) {
        max-width: 500px;
        margin: 0 auto;
      }

      &--inner {
        position: relative;
        padding-bottom: 50%;
      }

      img {
        position: absolute;

        .service__wrap:not(.reverse) & {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          max-height: 239px;
          max-width: 239px;
        }

        .reverse & {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--descr {
      @include property("font-size", 24, 21);
      letter-spacing: 0.48px;
    }
  }
}

.details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 330px));
  @include property("gap", 85, 20, true, 1024, 768);
  justify-content: center;
  @include property("margin-top", 70, 30);

  > * {
    img {
      display: block;
      width: 61px;
    }
  }

  &__wrap {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 3px solid #f9b80e;

    &--title {
      @include property("font-size", 28, 21);
      margin-bottom: 20px;
      color: #1c1c1c;
    }

    a {
      font-size: 16px;
      line-height: calc(30 / 16);
      letter-spacing: 2.4px;
      color: #f9b80e;
    }
  }

  &__left {
    display: flex;
    align-items: flex-start;
  }

  &__right {
    display: flex;
    align-items: flex-start;
  }
}
</style>
